<script lang="ts">
  import '$/css/style.css';
  import { ProgressBar } from '@prgm/sveltekit-progress-bar';
  import { page } from '$app/stores';
  import '$lib/sentry'; // Import Sentry for side effects
</script>

<svelte:head>
  <title>{$page.data.title ?? 'pome'}</title>
</svelte:head>

<slot />

<ProgressBar class="text-pome-salmon" zIndex={50} />
